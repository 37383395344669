import React, { FC } from 'react'
import { TWTCorePage, MainMenu } from '../twt-core'
import { Rows, NoSSR } from '../design-system'
import { fonts2020, theme2020 } from '../twt-2020/theme'
import { graphql } from 'gatsby'
import { CmsPageContext, TWT2020Theme, HomePageType } from '../cms'
import { LogoGraveyardElement } from '../cms/special-blocks'
import { PageFooter } from '../twt-core/organisms/page-footer'
import { themeColor } from '../twt-core/theme'
import { RegistrationScreen } from '../festival/organisms/registration'
import { EventGrid } from '../festival/organisms/event-list-block'
import { Heading, Display } from '../design-system/atoms/text'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { LayoutCard } from '../twt-core/molecules/layout-card'
import { eventCardApolloFragments } from '../festival/molecules/event-card'
import { LoadingPlaceholder } from '../design-system/atoms/loading-spinner'
import { SectionWrapper } from '../twt-core/molecules/section'
import { PageTitle } from '../twt-core/organisms/page-title'

const USER_EVENTS = gql`
  query currentUserEvents {
    currentUser {
      bookmarkedEvents {
        ...EventCard
      }
    }
  }

  ${eventCardApolloFragments}
`

export const query = graphql`
  query MyTwtQuery {
    wagtail {
      ...CmsSiteData
    }
  }
`

const MyTwt = ({
  data: {
    wagtail: { logoGraveyard, ...site },
  },
}) => {
  const { loading, data } = useQuery(USER_EVENTS, {
    fetchPolicy: 'network-only',
  })

  return (
    <CmsPageContext.Provider
      value={{
        pageType: HomePageType,
        theme: TWT2020Theme,
        subpages: [],
      }}
    >
      <TWTCorePage
        theme={theme2020}
        fonts={fonts2020}
        titlePrefix="TWT20"
        title="Register for your Festival Pass"
      >
        <MainMenu menuItems={site.home.children} />
        <SectionWrapper>
          <Display>My TWT</Display>
        </SectionWrapper>

        <SectionWrapper>
          <Heading>Saved events</Heading>

          <NoSSR fallback={<LoadingPlaceholder />}>
            {!data && <LoadingPlaceholder />}

            {!!data?.currentUser?.bookmarkedEvents?.length && (
              <EventGrid events={data?.currentUser.bookmarkedEvents} />
            )}
          </NoSSR>
        </SectionWrapper>
        <Rows bg={themeColor.bgReverse} color={themeColor.reverseContent}>
          <LogoGraveyardElement collections={logoGraveyard} />
          <PageFooter menuItems={site.home.children} />
        </Rows>
      </TWTCorePage>
    </CmsPageContext.Provider>
  )
}

export default MyTwt
